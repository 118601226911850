import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Importa Link da React Router
import { Title } from "../../themes/styled";

const HypeCocktailsContainer = styled.div`
    padding: 50px 80px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: 1365px) and (orientation: landscape) {
        padding: 50px;
    }

    @media (max-width: 1365px) and (orientation: portrait),
    (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        padding: 20px;
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 colonne di default */
    gap: 0;
    width: 100%;

    @media (max-width: 1365px) and (orientation: portrait) {
        grid-template-columns: repeat(2, 1fr); /* 2 colonne su mobile */
    }
`;

const GridItem = styled.div`
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    background-image: ${(props) => props.image ? `url(${props.image})` : 'none'};
    background-size: cover;
    background-position: center;
    position: relative;
    
    .title-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Sfondo nero semi-trasparente */
        color: white; /* Testo bianco */
        text-align: center;
        padding: 10px; /* Margine ridotto per evitare sovrapposizioni */
        box-sizing: border-box; /* Garantisce che il padding non superi la dimensione del contenitore */
        font-size: 18px; /* Dimensione testo leggermente ridotta */
        font-weight: bold;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none; /* Rimuove sottolineatura */
    color: inherit; /* Mantiene il colore del testo */
    display: block; /* Rende l'intera card cliccabile */
    width: 100%;
    height: 100%;
`;

const Text = styled.p`
    font-size: 1.5rem;

    @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        font-size: 1.2rem;
        text-align: center;
    }
`;

export default function HypeCocktails({ text, cocktails }) {
  console.log(cocktails)

  return (
    <HypeCocktailsContainer>
      <Title id={"title"}>COCKTAILS</Title>
      <Text>{text}</Text>
      <GridContainer>
        {cocktails.map((cocktail) => (
          <GridItem
            key={cocktail.id}
            image={cocktail.image}
            title={cocktail.title}
          >
            <StyledLink to={`/cocktail/${cocktail.id}`}>
              <div className="title-container">{cocktail.title}</div>
            </StyledLink>
          </GridItem>
        ))}
      </GridContainer>
    </HypeCocktailsContainer>
  );
}
