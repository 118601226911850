import React from 'react';
import styled from 'styled-components';
import SocialBar from "../SocialBar";

const backgroundImageUrl = 'https://liquorebonito.it/background/bottle.png';

const HomeDiv = styled.div``;

const PhotoContainer = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 80px;
    text-align: right;

    @media (max-width: 1365px) and (orientation: portrait) {
        height: 100vh;
        width: 100vw;
        background-image: url('https://liquorebonito.it/background/bottleMobile.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        padding: 0 0;
    }
`;

const SubPhotoContainer = styled.div`
    width: 45%;
    padding: 30px;
    flex-direction: column;
    text-align: left;

    @media (max-width: 1365px) and (orientation: portrait) {
        width: 100%;
        padding: 20px;
    }
`;

const Title = styled.h1`
    @media (max-width: 1365px) and (orientation: portrait) {
        position: absolute;
        right: 20px;
        color: white;
        font-size: 10vw;
        width: 50%;
        text-align: right;
    }

    @media (min-width: 769px) and (max-width: 1365px) and (orientation: portrait) {
        font-size: 5vw;
    }
`;

const TextContainer = styled.div`
    text-align: left;

    @media (max-width: 1365px) and (orientation: portrait) {
        padding: 20px;
        text-align: center; /* Centra il contenuto su mobile */
    }
`;

const DesktopText = styled.div`
    @media (max-width: 1366px) and (orientation: portrait){
        display: none;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        display: none;
    }
`;

const MobileText = styled.div`
    @media (min-width: 1366px) or (orientation: landscape) {
        display: none;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        display: block;
    }
`;

/*const Text = styled.p`
    font-size: 1.5rem;
    text-align: justify;

    @media (max-width: 1365px) and (orientation: portrait) {
        margin-top: 20px;
        font-size: 1rem;
    }
`;*/

const Text = styled.p`
    font-size: 1.5rem;
    text-align: justify;

    @media (max-width: 1365px) and (orientation: portrait) {
        margin-top: 20px;
        font-size: 1rem;
    }
`;

const StyledButton = styled.button`
    background-color: #A52A2A;
    color: #FFF6E0;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50px;
    width: 30%;
    display: inline-block;

    &:hover {
        background-color: darkred;
    }

    @media (max-width: 1365px) and (orientation: portrait) {
        width: 50%; /* Ridimensionato per mobile */
        margin: 20px auto; /* Centra il bottone */
    }

    @media (max-width: 768px) {
        width: 75%;
    }
`;

const SocialBarContainer = styled.div`
    position: absolute;
    bottom: 20px;
    left: 60px;

    @media (max-width: 1365px) and (orientation: portrait) {
        display: none;
    }
`;

export default function Home({ content, socials }) {
  const handleBuyNow = () => {
    window.open('https://caffebonito.it/shop/merchandising/liquore-al-caffe/', '_blank');
  };

  return (
    <HomeDiv>
      <PhotoContainer>
        <SubPhotoContainer>
          <Title id={"subPhoto"}>LIQUORE BONITO</Title>
          <DesktopText>
            <TextContainer>
              <Text id={"desktop"} dangerouslySetInnerHTML={{ __html: content.text }} />
              <StyledButton onClick={handleBuyNow}>Buy Now</StyledButton>
            </TextContainer>
          </DesktopText>
        </SubPhotoContainer>
        <SocialBarContainer>
          <SocialBar socials={socials} />
        </SocialBarContainer>
      </PhotoContainer>
      <MobileText>
        <TextContainer>
          <Text dangerouslySetInnerHTML={{ __html: content.text }} />
          <StyledButton onClick={handleBuyNow}>Buy Now</StyledButton>
        </TextContainer>
      </MobileText>
    </HomeDiv>
  );
}