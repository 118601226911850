import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from '../themes/colors';
import Home from '../components/landingPageComponents/Home';
import LoadingSpinner from '../components/LoadingSpinner';
import Contacts from '../components/landingPageComponents/Contacts';
import HypeCocktails from '../components/landingPageComponents/HypeCocktails';
import About from '../components/landingPageComponents/About';
import {fetchLandingPageData} from "../api/LandingPage";
import {getMaggiorenne} from "../api/Cookies";
import {useLocation} from 'react-router-dom';
import {getAllCocktailCards} from "../api/Cocktail";

export default function Root() {
  const [data, setData] = useState(null);
  const [maggiorenne, setMaggiorenne] = useState(false);
  const location = useLocation();
  const [cocktails, setCocktails] = useState(null);
  const [error, setError] = useState(false);

  const mapCocktailToCard = (cocktail) => ({
    title: cocktail.nomeCocktail, image: cocktail.urlImgCocktailMobile, id: cocktail.idCocktail
  });

  useEffect(() => {
    getMaggiorenne()
      .then(data => {
        if (data && data.value == "1") {
          setMaggiorenne(data.value === '1');
        }
      })
      .catch(error => {
        console.error("Error fetching maggiorenne status:", error);
      });

    fetchLandingPageData()
      .then(data => {
        setData(data);
      })
      .catch(error => {
        console.error("Error fetching landing page data:", error);
      });

    getAllCocktailCards()
      .then(data => {
        const parsedCocktails = data.map(item => mapCocktailToCard(item));
        /*parsedCocktails.push({
          title: "", image: "https://liquorebonito.it/background/background.jpg", id: null
        });*/
        setCocktails(parsedCocktails);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (data && location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [data, location]);

  /*if (!data || !cocktails) {
    return <LoadingSpinner/>;
  }
  else{
    console.log(data);
    console.log(cocktails);
  }*/

  if (data == null || cocktails == null) {
    return <LoadingSpinner/>;
  } else {
    return (<ThemeProvider theme={theme}>
      <>
        <section id="home">
          <Home content={JSON.parse(data.homeData.homeComponent)} socials={data.socials}/>
        </section>
        <section id="cocktails">
          <HypeCocktails cocktails={cocktails} text={data.cocktailText}/>
        </section>
        <section id="about">
          <About content={JSON.parse(data.homeData.about)}/>
        </section>
        <section id="contact">
          <Contacts content={JSON.parse(data.homeData.contact)}/>
        </section>
      </>
    </ThemeProvider>);
  }
}
