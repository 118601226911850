import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: ${({ imagePosition }) => (imagePosition === 'left' ? 'row-reverse' : 'row')};
    align-items: center;

    @media (max-width: 1365px) and (orientation: portrait) {
        flex-direction: column;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        flex-direction: column;
    }
`;

const TextColumn = styled.div`
    flex: 1;
    font-size: 1.5rem;
    padding: 1rem;

    @media (max-width: 1365px) and (orientation: portrait) {
        font-size: 1rem;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        font-size: 1rem;
    }

    /* Stile per accentuare il grassetto */
    strong {
        font-weight: 900;
    }
`;

const ImageColumn = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 95%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        img {
            width: 80%;
        }
    }
`;

export default function ImageAndText({ content, imagePosition }) {
  return (
    <Container imagePosition={imagePosition}>
      <TextColumn dangerouslySetInnerHTML={{ __html: content.text }} />
      <ImageColumn>
        <img src={content.image} alt="Content visual" />
      </ImageColumn>
    </Container>
  );
}
